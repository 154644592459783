<template>
  <v-container fluid grid-list-xl>
    <v-layout wrap>
      <v-flex md12 sm12 lg12>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <div class="card-title float-left">Synchronisation</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-btn color="primary" large @click="getAllData" :disabled="!online">
              <i class="material-icons">refresh</i>Aktuelle Daten von Server synchronisieren
            </v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <div class="card-title float-left">Offline-Daten</div>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-card class="mx-auto my-4" outlined>
              <v-card-title>
                <span class="headline">Anlagen</span>
              </v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="anlagen"
                  hide-default-footer
                  class="elevation-1"
                  v-if="anlagen && anlagen.length"
                >
                  <template v-slot:item.action="{ item }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          @click="$router.push('/anlage/edit/'+item.id + '/-1/' + item.customerid + '/o')"
                          color="primary"
                          small
                          fab
                        >
                          <i class="material-icons">settings</i>
                        </v-btn>
                      </template>
                      <span>Anlage bearbeiten</span>
                    </v-tooltip>

                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="primary"
                          small
                          fab
                          @click="syncAnlage(item)"
                          :disabled="!online"
                        >
                          <i class="material-icons">refresh</i>
                        </v-btn>
                      </template>
                      <span>Anlage mit Datenbank synchronisieren</span>
                    </v-tooltip>
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="error" small fab @click="removeAnlage(item.id)">
                          <i class="material-icons">remove</i>
                        </v-btn>
                      </template>
                      <span>Anlage aus dem Offline-Speicher entgültig entfernen</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                 <div class="text-orange">Es befinden sich keine Anlagen im Offline-Speicher</div>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="success"
                  class="float-right"
                  small
                  @click="syncAllAnlagen()"
                  :disabled="!online || !anlagen.length"
                >
                  <i class="material-icons">refresh</i>Alle Anlagen synchronisieren
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-card class="mx-auto my-4" outlined>
              <v-card-title>
                <span class="headline">Anlage-Wartungen</span>
              </v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="headerswartungen"
                  :items="wartungen"
                  hide-default-footer
                  class="elevation-1"
                  v-if="wartungen && wartungen.length"
                >
                  <template v-slot:item.status="{ item }">
                    <div class="text-center">
                      <i
                        style="color:green"
                        v-if="item.status === '1' || item.status === 1"
                        class="material-icons"
                      >check_circle</i>
                      <i v-else style="color:red" class="material-icons">remove_circle</i>
                    </div>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          @click="$router.push('/anlagewartung/execute/' + item.id + '/' + item.wid + '/' + item.blockid + '/o')"
                          color="primary"
                          small
                          fab
                        >
                          <i class="material-icons">settings</i>
                        </v-btn>
                      </template>
                      <span>Wartung bearbeiten</span>
                    </v-tooltip>

                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="primary"
                          small
                          fab
                          @click="syncWartung(item)"
                          :disabled="!online"
                        >
                          <i class="material-icons">refresh</i>
                        </v-btn>
                      </template>
                      <span>Anlage-Wartung mit Datenbank synchronisieren</span>
                    </v-tooltip>
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="error" small fab @click="removeWartung(item.id)">
                          <i class="material-icons">remove</i>
                        </v-btn>
                      </template>
                      <span>Anlage-Wartungen aus dem Offline-Speicher entgültig entfernen</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <div v-else class="text-orange">Es befinden sich keine Anlage-Wartungen im Offline-Speicher</div>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="success"
                  class="float-right"
                  small
                  @click="syncAllAnlagen()"
                  :disabled="!online || !wartungen.length"
                >
                  <i class="material-icons">refresh</i>Alle Anlage-Wartungen synchronisieren
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      anlagen: [],
      wartungen: [],
      changes: false,
      measurements: [
        {
          value: "1",
          text: "Soll/Ist-Wert Messung"
        },
        {
          value: "2",
          text: "Ist-Wert Messung"
        }
      ],
      headers: [
        {
          text: "Name",
          align: "left",
          sortable: false,
          value: "name"
        },
        {
          text: "Gebäude",
          align: "left",
          sortable: false,
          value: "gebaeude"
        },
        {
          text: "Standort",
          align: "left",
          sortable: false,
          value: "standort"
        },
        {
          text: "Typ",
          align: "left",
          sortable: false,
          value: "typ"
        },
        {
          text: "Seriennummer",
          align: "left",
          sortable: false,
          value: "seriennummer"
        },
        {
          text: "Kommentar",
          align: "left",
          sortable: false,
          value: "comment"
        },
        {
          text: "Baujar",
          align: "left",
          sortable: false,
          value: "baujahr"
        },
        {
          text: "Zustand",
          align: "left",
          sortable: false,
          value: "mode"
        },
        {
          text: "Kundennummer",
          align: "left",
          sortable: false,
          value: "customerid"
        },
        {
          text: "Aktion",
          align: "left",
          sortable: false,
          value: "action"
        }
      ],
      headerswartungen: [
        {
          text: "Anlagenbezeichnung",
          align: "left",
          sortable: false,
          value: "name"
        },
        {
          text: "Gebäude",
          align: "left",
          sortable: false,
          value: "gebaeude"
        },
        {
          text: "Standort",
          align: "left",
          sortable: false,
          value: "standort"
        },
        {
          text: "Typ",
          align: "left",
          sortable: false,
          value: "typ"
        },
        {
          text: "Wartungsnummer",
          align: "center",
          sortable: false,
          value: "wid"
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "status"
        },
        {
          text: "Aktion",
          align: "center",
          sortable: false,
          value: "action"
        }
      ]
    };
  },
  computed: {
    online() {
      return this.$store.getters.online;
    }
  },
  mounted() {
    this.getOfflineData();
  },
  methods: {
    syncAnlage(item) {
      this.syncAnlagen([item]);
    },
    syncAllAnlagen() {
      this.syncAnlagen(this.anlagen);
    },
    syncWartung(item) {
      this.syncWartungen([item]);
    },
    syncAllWartungen() {
      this.syncWartungen(this.wartungen);
    },
    syncAnlagen(items) {
      let formData = new FormData();
      formData.append("items", JSON.stringify(items));
      this.$http({
        method: "post",
        url: "syncAnlagen.php",
        data: formData
      })
        .then(response => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then(resp => {
                this.$router.push("/login");
              })
              .catch(err => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            alert(
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator"
            );
            return;
          }
          if (response.data && response.data.success) {
            response.data.result.forEach((res, i) => {
              if (res) {
                this.anlagen.splice(i, 1);
              }
            });
            const msg = "Die Anlagen wurden erfolgreich synchronisiert.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
            this.$store.dispatch("setAnlagenOffline", this.anlagen);
          } else {
            const msg = "Die Anlagen wurden nicht erfolgreich synchronisiert.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function(error) {
          error;
        });
    },
    syncWartungen(items) {
      let formData = new FormData();
      formData.append("items", JSON.stringify(items));
      this.$http({
        method: "post",
        url: "syncAnlageWartungen.php",
        data: formData
      })
        .then(response => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then(resp => {
                this.$router.push("/login");
              })
              .catch(err => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            alert(
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator"
            );
            return;
          }
          if (response.data && response.data.success) {
            response.data.result.forEach((res, i) => {
              if (res) {
                this.wartungen.splice(i, 1);
              }
            });
            const msg = "Die Wartungen wurden erfolgreich synchronisiert.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
            this.$store.dispatch("setWartungenOffline", this.wartungen);
          } else {
            const msg =
              "Die Wartungen wurden nicht erfolgreich synchronisiert.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function(error) {
          error;
        });
    },
    removeWartung(id) {
      const data = this.$store.getters.offlinedata;
      if (data && data.offlinewartungen) {
        data.offlinewartungen.forEach((a, i) => {
          if (a.id === id) {
            data.offlinewartungen.splice(i, 1);
          }
        });
        this.$store.dispatch("setWartungenOffline", data.offlinewartungen);
      }
    },
    removeAnlage(id) {
      const data = this.$store.getters.offlinedata;
      if (data && data.offlineanlagen) {
        data.offlineanlagen.forEach((a, i) => {
          if (a.id === id) {
            data.offlineanlagen.splice(i, 1);
          }
        });
        this.$store.dispatch("setAnlagenOffline", data.offlineanlagen);
      }
    },
    getOfflineData() {
      const data = this.$store.getters.offlinedata;
      if (data.offlineanlagen && data.offlineanlagen.length) {
        this.anlagen = data.offlineanlagen;
      }
      if (data.offlinewartungen && data.offlinewartungen.length) {
        this.wartungen = data.offlinewartungen;
      }
    },
    getAllData() {
      let self = this;
      this.$http({
        method: "post",
        url: "getSyncableData.php"
      })
        .then(response => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then(resp => {
                this.$router.push("/login");
              })
              .catch(err => {
                console.log(err);
              });
          }
          if (response.data && response.data.success) {
            let data = this.$store.getters.offlinedata;
            if (response.data.todos) {
              data.todowartungen = response.data.todos;
            }
            if (response.data.assignments) {
              data.assignments = response.data.assignments;
            }
            if (response.data.next) {
              data.nextwartungen = response.data.next;
            }
            if (response.data.finalized) {
              data.finalizedwartungen = response.data.finalized;
            }
            if (response.data.users) {
              data.users = response.data.users;
            }
            if (response.data.leiters) {
              data.leiters = response.data.leiters;
            }
            if (response.data.customers) {
              data.customers = response.data.customers;
            }
            if (response.data.anlagen) {
              data.anlagen = response.data.anlagen;
            }
            if (response.data.blocke) {
              data.blocke = response.data.blocke;
            }
            if (response.data.structure) {
              data.structure = response.data.structure;
            }
            if (response.data.standorte) {
              data.standorte = response.data.standorte;
            }
            if (response.data.wartungsdetails) {
              data.wartungsdetails = response.data.wartungsdetails;
            }
            if (response.data.anlagenwartungen) {
              data.anlagenwartungen = response.data.anlagenwartungen;
            }
            this.$store.dispatch("setOfflineData", data);
            const msg = "Daten wurden erfolgreich vom Server synchronisiert.";
            const color = "success";
            self.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function(error) {
          const msg = "Daten konnten nicht synchronisiert werden.";
          const color = "error";
          self.$store.dispatch("snackbar", { msg, color });
        });
    }
  }
};
</script>