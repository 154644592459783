var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"md12":"","sm12":"","lg12":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('div',{staticClass:"card-title float-left"},[_vm._v("Synchronisation")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-btn',{attrs:{"color":"primary","large":"","disabled":!_vm.online},on:{"click":_vm.getAllData}},[_c('i',{staticClass:"material-icons"},[_vm._v("refresh")]),_vm._v("Aktuelle Daten von Server synchronisieren ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('div',{staticClass:"card-title float-left"},[_vm._v("Offline-Daten")])]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-card',{staticClass:"mx-auto my-4",attrs:{"outlined":""}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Anlagen")])]),_c('v-card-text',[(_vm.anlagen && _vm.anlagen.length)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.anlagen,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","small":"","fab":""},on:{"click":function($event){return _vm.$router.push('/anlage/edit/'+item.id + '/-1/' + item.customerid + '/o')}}},on),[_c('i',{staticClass:"material-icons"},[_vm._v("settings")])])]}}],null,true)},[_c('span',[_vm._v("Anlage bearbeiten")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","small":"","fab":"","disabled":!_vm.online},on:{"click":function($event){return _vm.syncAnlage(item)}}},on),[_c('i',{staticClass:"material-icons"},[_vm._v("refresh")])])]}}],null,true)},[_c('span',[_vm._v("Anlage mit Datenbank synchronisieren")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"error",attrs:{"small":"","fab":""},on:{"click":function($event){return _vm.removeAnlage(item.id)}}},on),[_c('i',{staticClass:"material-icons"},[_vm._v("remove")])])]}}],null,true)},[_c('span',[_vm._v("Anlage aus dem Offline-Speicher entgültig entfernen")])])]}}],null,false,371938533)}):_vm._e(),_c('div',{staticClass:"text-orange"},[_vm._v("Es befinden sich keine Anlagen im Offline-Speicher")])],1),_c('v-card-actions',[_c('v-btn',{staticClass:"float-right",attrs:{"color":"success","small":"","disabled":!_vm.online || !_vm.anlagen.length},on:{"click":function($event){return _vm.syncAllAnlagen()}}},[_c('i',{staticClass:"material-icons"},[_vm._v("refresh")]),_vm._v("Alle Anlagen synchronisieren ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-card',{staticClass:"mx-auto my-4",attrs:{"outlined":""}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Anlage-Wartungen")])]),_c('v-card-text',[(_vm.wartungen && _vm.wartungen.length)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headerswartungen,"items":_vm.wartungen,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[(item.status === '1' || item.status === 1)?_c('i',{staticClass:"material-icons",staticStyle:{"color":"green"}},[_vm._v("check_circle")]):_c('i',{staticClass:"material-icons",staticStyle:{"color":"red"}},[_vm._v("remove_circle")])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","small":"","fab":""},on:{"click":function($event){return _vm.$router.push('/anlagewartung/execute/' + item.id + '/' + item.wid + '/' + item.blockid + '/o')}}},on),[_c('i',{staticClass:"material-icons"},[_vm._v("settings")])])]}}],null,true)},[_c('span',[_vm._v("Wartung bearbeiten")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","small":"","fab":"","disabled":!_vm.online},on:{"click":function($event){return _vm.syncWartung(item)}}},on),[_c('i',{staticClass:"material-icons"},[_vm._v("refresh")])])]}}],null,true)},[_c('span',[_vm._v("Anlage-Wartung mit Datenbank synchronisieren")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"error",attrs:{"small":"","fab":""},on:{"click":function($event){return _vm.removeWartung(item.id)}}},on),[_c('i',{staticClass:"material-icons"},[_vm._v("remove")])])]}}],null,true)},[_c('span',[_vm._v("Anlage-Wartungen aus dem Offline-Speicher entgültig entfernen")])])]}}],null,false,3685845740)}):_c('div',{staticClass:"text-orange"},[_vm._v("Es befinden sich keine Anlage-Wartungen im Offline-Speicher")])],1),_c('v-card-actions',[_c('v-btn',{staticClass:"float-right",attrs:{"color":"success","small":"","disabled":!_vm.online || !_vm.wartungen.length},on:{"click":function($event){return _vm.syncAllAnlagen()}}},[_c('i',{staticClass:"material-icons"},[_vm._v("refresh")]),_vm._v("Alle Anlage-Wartungen synchronisieren ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }